import styles from './App.module.css';
import logo from './logo.svg';
import { MainPageHook, ResultsPageHook } from "./Model";
import { CanvasJSChart } from 'canvasjs-react-charts';
import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink as Link
  } from "react-router-dom";

export function MainPage() {

    let [
        calculateButtonCallback,
        sentenceInput,
        setSentenceInput,
        isLoading
    ] = MainPageHook();

    return (
        <Page>
            <div className={styles.ColumnContent}>
                <div className={styles.Left}>
                    <section>
                        <h1>Syntactic Surprise Calculator</h1>
                        <p>
                        If you use this website in your research, please cite our research:
                        </p><p>
                        A. Selin Atalay, Siham El Kihal, and Florian Ellsaesser (2023), “Creating Effective Marketing Messages Through Moderately Surprising Syntax”, <i>Journal of Marketing</i> <a href="https://doi.org/10.1177/00222429231153582">https://doi.org/10.1177/00222429231153582</a>
                        </p>
                    </section> 
                    <section>
                        <h2>Copy and paste your text into the text box</h2>
                        <p>
                            The algorithm will analyze your text and measure the syntactic surprise and tell you:
                        </p>
                        <ul>
                            <li>What your syntactic surprise is</li>
                            <li>Whether your syntactic surprise is in the “effective” syntactic surprise range or whether you should modify the syntax of your text</li>
                        </ul>
                    </section>  
                    <section>
                        <h2>How is the “effective” syntactic surprise range defined?</h2>
                        <p>
                            The “effective” range of syntactic surprise is defined based on a series of scientific studies conducted in various marketing contexts. 
                        </p>
                        {/* <p>Click <Link to="/green-zone/">here</Link> for details.</p> */}
                    </section>
                    <section>
                        <h2>How is syntactic surprise measured?</h2>
                        <p>
                            In Atalay, El Kihal, & Ellsaesser (2023), you will find a detailed description of the origins and mathematical definition of syntactic surprise.
                        </p>
                    </section>
                </div>    
                <div className={styles.Right}>
                    <textarea value={sentenceInput} onChange={e => setSentenceInput(e.target.value)} placeholder="Paste text here.."></textarea>
                    {/* <div className={styles.ErrorBucket}></div> */}
                    <button onClick={calculateButtonCallback}>Calculate syntactic surprise<LoadingIcon loading={isLoading} /></button>
                </div>
            </div>
        </Page>
    );
}

function LoadingIcon({ loading }) {
    if (!loading) return null;
    return (
        <svg className={styles.SpinIcon} viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
    );
}

export function SurprisePage() {
    return (
        <Page>
            <div className={styles.ColumnContent}>
                <div className={styles.Left}>
                    <section>
                    <h1>Surprise Measurement (INTUITION)</h1>
                    <ul>
                        <li>Surprise captures the ease or difficulty of processing the probabilistic variation in language.</li>
                        <li>Surprise (Shannon, 1948) reflects the surprisal, uncertainty about the rest of the sentence, given the expectation violations encountered (Hale 2006). Surprisal in the message syntax impacts the cognitive effort required to process the syntactic elements (Hale 2001; Levy 2008; Balling and Baayen 2012). </li>
                        <li>Reducing surprise eases the burden on the human sentence processing mechanism and reduces working memory load (Hale 2003, 2006, 2011). The impact of surprise on the working memory system is reflected in behavioral (e.g. reading time) as well as neurophysiological (e.g. event-related potential (ERP)) measures (see Crocker et al. 2016 for an overview, Degaetano-Ortlieb and Teich 2019).</li>
                    </ul>
                    </section>
                </div>    
            </div>
        </Page>
    );
}

export function GreenZonePage() {
    return (
        <Page>
            <div className={styles.ColumnContent}>
                <div className={styles.Left}>
                    <section>
                    <h1>“Green zone” for Effective Marketing Communications</h1>
                    <ul>
                        <li>
                        Based on a series of scientific studies (7 studies) where we used publicly available data and run field experiments in different contexts (such as product descriptions, customer reviews, or), we could identify an “Effective” range of surprise, where texts had the highest efficiency regarding relevant marketing outcomes.
                        </li>
                    </ul>
                    </section>
                </div>    
            </div>
        </Page>
    );
}

export function ResultPage({ match }) {

    const [ 
        surprise,
        hash_key,
        can_rerun,
        other_calculations,
        historyCleanup,
        evaluated_data,
    ] = ResultsPageHook(decodeURIComponent(match.params.surprise), match.params.hash);

    return (
        <Page>
            <div className={styles.ColumnContent}>
                <div className={styles.Left}>
                    <section>
                    <h1>Results</h1>
                    <p>
                        The surprise of your text is <b>{surprise.toFixed(2)}.</b> <br />
                        This value is {
                            (surprise > 2.0 && surprise <= 2.2) ? "inside" : "outside"
                        } the “effective” range of surprise.
                    </p>
                    {(can_rerun) ? <ColoredTextBlock meta={evaluated_data} /> : null}
                    {
                        (can_rerun) ?
                        <p>Please modify your text and <a href={"/?saved=" + hash_key}>Go to the calculator</a> to see how your syntax modifications changed syntactic surprise.</p> : null
                    }
                    </section>
                </div>    
                <div className={styles.Right}>
                    <div className={styles.Graph}>
                        <GraphMarker pos={surprise} />
                        <img src="/scale-graph.png" alt="Surprise"></img>
                        <div className={styles.SurprisingText}>
                            <span>too unsurprising syntax</span>
                            <span style={{ textAlign: "right" }}>too surprising syntax</span>
                        </div>
                    </div>
                </div>
            </div>
            {
                (other_calculations.length > 0) ?
                <section>
                    <h2>Other runs</h2>
                    <p>
                        View your previous runs. You can also<button className={styles.ButtonAsLink} onClick={historyCleanup}>clear them.</button>
                    </p>
                    <ul>
                    {
                        other_calculations.map((data, i) => (
                            <OldSurpriseCard key={i} data={data} />
                        ))
                    }
                    </ul>
                </section> : null
            }
        </Page>
    );
}

function ColoredTextBlock({ meta }) {
    if (meta.sentences && meta.results && meta.results.length > 0) {
        if (meta.results.length === meta.sentences.length) {
            return (
                <>
                    <p>To see surprise by sentence, hover the mouse over the corresponding part of the text.</p>
                    <div className={styles.ResultSentences}>
                        <p>
                        {meta.sentences.map((s, i) => (<ColoredSentence key={i} text={s} sentenceSurprise={+meta.results[i]}/>))}
                        </p>
                    </div>
                </>
            )
        }
    }
    return <></>;
}

function ColoredSentence({ text, sentenceSurprise }) {

    const [showAlt, setShowAlt] = useState(false);

    // let [ brown, orange, yellow, green, cyan, blue, purple ] = [
    //     [122,  15,   0], 
    //     [247, 106,  24], 
    //     [251, 180,  54],
    //     [158, 245,  52], 
    //     [ 29, 227, 180], 
    //     [ 65, 152, 255],
    //     [ 52,  24,  69],
    // ];


    const COLORS = [
        [0,   '#440154'],
        [0.06,'#481567'],
        [0.12,'#482677'],
        [0.17,'#453781'],
        [0.23,'#404788'],
        [0.3, '#39568c'],
        [0.35,'#33638d'],
        [0.39,'#2d708e'],
        [0.43,'#287d8e'],
        [0.48,'#238a8d'], 
        [0.5, '#1f968b'],
        [0.51,'#20a387'],
        [0.52,'#29af7f'],
        [0.53,'#3cbb75'], 
        [0.55,'#55c667'],
        [0.6, '#73d055'], 
        [0.62,'#95d840'],
        [0.63,'#b8de29'],
        [0.64,'#dce319'],  
        [0.66,'#efe350'],
        [0.7, '#f7cb44'],
        [0.73,'#f9b641'],
        [0.76,'#f9a242'],
        [0.8, '#f68f46'],
        [0.85,'#eb8055'],
        [0.87,'#de7065'],
        [0.9, '#d44842'],
        [1,   '#9f2a63']  
    ]

    // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    const hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
    };

    const gradHex = (hex_1, hex_2, grad) => {

        let c1 = hexToRgb(hex_1);
        let c2 = hexToRgb(hex_2);
        const flow = (x1, x2) => x1 + grad * (x2 - x1);
        return [flow(c1.r, c2.r), flow(c1.g, c2.g), flow(c1.b, c2.b)];
    }

    const findColor = (e) => {
        for (let i = 0; i < COLORS.length - 1; i++) {
            let [a, v1] = COLORS[i];
            let [b, v2] = COLORS[i + 1];

            if (e === a) return gradHex(v2, v1, 0);
            if (e === b) return gradHex(v2, v2, 0);

            if (e > a && e < b) return gradHex(v1, v2, (e - a) / (b - a));
        }
    }

    let SurpriseScaled = (Math.max(1, Math.min(sentenceSurprise, 3)) - 1) / 2;
    let color = findColor(SurpriseScaled);

    // let color = null;

    // const gradColors = (c1, c2, s) => {
    //     let o = [];
    //     for (let i = 0; i < 3; i++) {
    //         o.push(Math.min(c1[i], c2[i]) + Math.abs(c1[i] - c2[i]) * s);
    //     }
    //     return o;
    // };

    // if (sentenceSurprise <= 1.0) {
    //     color = brown;
    // } else if (sentenceSurprise <= 1.4) {
    //     color = gradColors(orange, brown, (1.4 - sentenceSurprise) / 0.4);
    // } else if (sentenceSurprise <= 1.8) {
    //     color = gradColors(orange, yellow, (1.8 - sentenceSurprise) / 0.4);
    // } else if (sentenceSurprise <= 2.1) {
    //     color = gradColors(yellow, green, (2.1 - sentenceSurprise) / 0.3);
    // } else if (sentenceSurprise <= 2.3) {
    //     color = gradColors(green, cyan, (2.3 - sentenceSurprise) / 0.2);
    // }  else if (sentenceSurprise <= 2.5) {
    //     color = gradColors(blue, cyan, (2.5 - sentenceSurprise) / 0.2);
    // }  else {
    //     color = blue;
    // }
    //console.log(sentenceSurprise);

    return (
        <>
            <span onMouseEnter={_ => setShowAlt(true)} onMouseLeave={_ => setShowAlt(false)} className={styles.Sentence} style={{color: 'rgb(' + color.join(',') + ')'}}>{text} 
            {(showAlt) ? (<span className={styles.SentenceAlt} style={{ backgroundColor: 'rgb(' + color.join(',') + ')' }}>{sentenceSurprise.toFixed(2)}</span>) : null}
            </span>
            
        </>
    )
}

function OldSurpriseCard({ data }) {
    return (
        <li>
            <i>{data["text"].substring(0, 100)}</i>
            <p>
                With surprise {data["Surprise"].toFixed(2)}
            </p>
            <Link to={data["url"]}>View the report</Link>
        </li>
    )
}

function GraphMarker({ pos }) {;
    pos = Math.max(0, pos - 1);
    let marker_x = Math.min(100, pos * 50);
    return (
        <div className={styles.GraphMarker} style={{ left: `${marker_x}%` }}></div>
    )
}

function Page({ children }) {
    return (
        <div className={styles.Application}>
            <header className={styles.Header}>
                <Link to="/">
                    <img src={logo} className={styles.Logo} alt="logo" />
                </Link>
                {/* <nav> */}
                    {/* <Link to="/surprise/" activeClassName={styles.ActiveLink}>Syntactic Surprise Measurement</Link> */}
                    {/* <Link to="/example/">Example</Link> */}
                    {/* <Link to="/green-zone/" activeClassName={styles.ActiveLink}>Effective Range of Syntactic Surprise</Link> */}
                {/* </nav> */}
            </header>
            {children}
        </div>
    );
}




