import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom";


async function calculateSurprise(request) {
    // Shared Node
    let URL = "https://api.syntactic-surprise-calculator.com/";
    // GPU Node 
    // let URL = "https://a100-api.syntactic-surprise-calculator.com/"
    // Local node
    // let URL = "http://127.0.0.1:8000/";

    let options = {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json'
        }
    }
    let response = await fetch(URL, options);
    let json_response =  await response.json();

    if ("error" in json_response) {
        throw new Error(json_response["error"]);
    }

    return json_response;

}


async function sha256(source) {
    const sourceBytes = new TextEncoder().encode(source);
    const digest = await crypto.subtle.digest("SHA-256", sourceBytes);
    const resultBytes = [...new Uint8Array(digest)];
    return resultBytes.map(x => x.toString(16).padStart(2, '0')).join("");
}

function getStoredSurpriseHashes() {
    let storage_item = localStorage.getItem('Surprise');
    return (!!storage_item) ? storage_item.split(";") : []
}

async function storeSurprise(encoded_text, encoded_Surprise, results, sentences) {
    let existing_items = getStoredSurpriseHashes()
    let text_hash = await sha256(encoded_text);
    if (existing_items.includes(text_hash)) return text_hash;
    existing_items.push(text_hash);
    localStorage.setItem('Surprise', existing_items.join(";"));
    localStorage.setItem(text_hash, `${encoded_text}|${encoded_Surprise}|${results.join('#')}|${sentences.map((s) => new Buffer(s).toString('base64')).join('#')}`);
    return text_hash;
}

function listStoredSurprise() {
    let existing_items = getStoredSurpriseHashes();
    let output_data = [];
    for (let hash_key of existing_items) {
        let item = extratStoredSurprise(hash_key);
        if (!!item) {
            output_data.push(item);
        }
    }
    return output_data;
}

function clearStoredSurprise() {
    localStorage.clear();
}

function extratStoredSurprise(hash_key) {
    let item_data = localStorage.getItem(hash_key)
    if (!!item_data) {
        let split_data = item_data.split("|");
        return {
            'key': hash_key, 
            'text': new Buffer(split_data[0], 'base64').toString(), 
            'Surprise': Buffer.from(split_data[1], 'base64').readDoubleLE(0),
            'results': (!!split_data[2]) ? split_data[2].split('#') : [],
            'sentences': (!!split_data[3]) ? split_data[3].split('#').map((b) => new Buffer(b, 'base64').toString()) : [],
            "url": `/result/${encodeURIComponent(split_data[1])}/${hash_key}/`
        }
    }
    return null;
}

export function MainPageHook() {

    const history = useHistory();
    const location = useLocation();

    let params = new URLSearchParams(location.search);
    let last_text = params.get("saved");
    
    if (!!last_text) {
        let latest_result = extratStoredSurprise(last_text);
        last_text = (!!latest_result) ? latest_result['text'] : null;
    }

    const [sentenceInput, setSentenceInput] = useState(last_text || "");
    const [isLoading, setIsLoading ] = useState(false);

    const calculateButtonCallback = () => {
        setIsLoading(true);
        loadSurpriseResult().then(_ => {
            // setIsLoading(false)
        });
    };

    async function loadSurpriseResult() {
        try {
            let { entropy, results, sentences } = await calculateSurprise({ sentence: sentenceInput });
            let hash = await storeSurprise(new Buffer(sentenceInput).toString('base64'), entropy, results, sentences);
            history.push("/result/" + encodeURIComponent(entropy) + "/" + hash);
        } 
        catch(e) {
            console.error(e);
        }
    }

    return [
        calculateButtonCallback,
        sentenceInput,
        setSentenceInput,
        isLoading
    ]
}

export function ResultsPageHook(encoded_Surprise, hash_key) {

    // We store Surprise results in local starage
    // That will not work if user wants to share this link to someone else
    // So we also store encoded Surprise itself in URL

    const Surprise = Buffer.from(encoded_Surprise, 'base64').readDoubleLE(0);
    const [other_calculations, set_other_calculations] = useState(listStoredSurprise());
    const [can_rerun, set_can_rerun] = useState(false);
    const [evaluated_data, setEvData] = useState(null);

    useEffect(() => {
        if (!!evaluated_data) {
            set_can_rerun(true);
            let new_other_calculations = listStoredSurprise().filter(
                (data) => data['key'] !== evaluated_data['key']
            );
            set_other_calculations(new_other_calculations);
            
        }
    }, [hash_key, Surprise, evaluated_data]);

    useEffect(() => {
        setEvData(extratStoredSurprise(hash_key));
    }, [hash_key]);

    const historyCleanup = (ev) => {
        clearStoredSurprise();
        set_other_calculations([]);
    };

    return [
        Surprise,
        hash_key,
        can_rerun,
        other_calculations,
        historyCleanup,
        evaluated_data
    ]
}


