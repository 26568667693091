
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import { MainPage, SurprisePage, GreenZonePage, ResultPage } from  "./Views.jsx";

function App() {
  return (
    <Router>
      <Switch>
          <Route exact path="/" component={MainPage} />
          <Route path="/surprise" component={SurprisePage} />
          <Route path="/example"></Route>
          <Route path="/green-zone">
              <GreenZonePage />
          </Route>
          <Route path="/result/:surprise/:hash?" component={ResultPage} />
        </Switch>
    </Router>
   
  );
}

export default App;
